import React, { useEffect, useState, useRef, memo } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Link from '@material-ui/core/Link'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { API } from 'aws-amplify'
import { createComm }  from '../graphql/mutations'
import { aspireMCBookingEmail, addMessageToBP } from '../utils/booking-api'

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 20,
    marginBottom: 20
  },
  close: {
    justifyContent: "flex-end"
  }  
}))

function ContactForm({theme, triggerOpen, initOpen}) {
  const [open, setOpen] = useState(false)
  const didMountRef = useRef(false)
  const [name, setName] = useState('')
  const [patName, setPatName] = useState('')
  const [dob, setDob] = useState('')
  const [testResult, setTestResult] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [preferredMethod, setPreferredMethod] = useState('text')
  const [reason, setReason] = useState('test')

  const classes = useStyles(theme)

  useEffect(() => {
    if (didMountRef.current) {
      setOpen(true)
      setMessage('')
    }
    else {
      didMountRef.current = true
      setOpen(initOpen)
    }
  }, [triggerOpen, initOpen])

  const handleSend = async () => {
    const subject = reason !== "else" ? `Re: ${patName} ${dob} ${testResult}` : ''
    const summary = `${subject}    ${message}`

    try {
      await API.graphql({
        query: createComm,
        variables: {
          input: {
            name: name,
            phone: phone,
            email: email,
            message: summary, 
            preferredMethod: preferredMethod,
            isFeedback: reason == 'test',
            forAppointment: reason == 'appointment', 
            reply: "",
            resolved: "N"
          }
        },
        authMode: 'AWS_IAM'
      })

      //Send a copy to messageus@aspiremedicalcentre.com.au
      console.log('Message us')
      const messageBody = `name: ${name},
        phone: ${phone},
        email: ${email},
        message: ${summary}`
      
      const response = await fetch(`${process.env.GATSBY_ASPIRE_COMM_SERVER}/send-email`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          emails: ['messageus@aspiremedicalcentre.com.au'],
          body: messageBody,
          subject: 'Message Us',
          source: aspireMCBookingEmail   
        })
      })

      const code = await response.json()

      if (code === 0) {
        //Prompt staff by sending a message to BP
        await addMessageToBP(messageBody)

        alert("Message was delivered successfully.")
      } else {
        alert("Message sending failed.")  
      }
    } catch (err) {
      console.error('Amplify CreateComms error...: ', err)
      alert(`Message sending failed: ${err}`)
    }

    setOpen(false)
  }

  return (
    <>
      <Dialog open={open} onBackdropClick={() => setOpen(false)}>
        <IconButton edge="start" color="inherit" className={classes.close} onClick={() => setOpen(false)} aria-label="close">
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <h1 className="pt-3 pb-2 text-left h1-responsive font-weight-bold" >Contact Us</h1>
          <Typography variant='subtitle2' align="left" gutterBottom>
            Here is a more convenient way to contact us than phone. Send us a message relating to the availability of your test result or appointment (e.g. phone consult request)
            or other administrative issues (e.g. online booking errors) and we'll respond to you as soon as possible. DO NOT use this form for emergency.
          </Typography>
          <Typography variant='subtitle2' align="left">
            Please let us know how you'd like us to get back to you.
          </Typography>          
          <FormControl component="fieldset">
            <RadioGroup 
              row
              aria-label="preferred-method" 
              name="preferred-method" 
              value={preferredMethod} 
              onChange={(event) => setPreferredMethod(event.target.value)}
            >
              <FormControlLabel value="text" control={<Radio />} label="text" id="prefer-text"/>
              <FormControlLabel value="call" control={<Radio />} label="call back" id="prefer-call"/>
              <FormControlLabel value="email" control={<Radio />} label="email" id="prefer-email"/>
            </RadioGroup>
          </FormControl>
          <Typography variant='subtitle2' align="left">
            My inquiry/request is for: 
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup 
              row
              aria-label="reason" 
              name="reason" 
              value={reason} 
              onChange={(event) => setReason(event.target.value)}
            >
              <FormControlLabel value='test' control={<Radio />} label="test result" id="test"/>
              <FormControlLabel value='appointment' control={<Radio />} label="appointment" id="appointment"/>
              <FormControlLabel value='else' control={<Radio />} label="other administrative question" id="else"/>
            </RadioGroup>
          </FormControl>
          <TextField
            id="contact-name"
            required
            margin="dense"
            label="your name"
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            id="contact-mobile"
            required
            margin="dense"
            label="mobile phone"
            type="tel"
            fullWidth
            variant="outlined"
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
          {
            reason !== "else"
            &&
            <TextField
              id="pat-name"
              required
              margin="dense"
              label="patient's name"
              type="text"
              fullWidth
              variant="outlined"
              value={patName}
              onChange={(event) => setPatName(event.target.value)}
            />
          }
          {
            reason !== "else"
            &&
            <TextField
              id="dob"
              required
              margin="dense"
              label="patient's DOB"
              type="text"
              fullWidth
              variant="outlined"
              value={dob}
              onChange={(event) => setDob(event.target.value)}
            />
          }
          {
            reason === "test"
            &&
            <TextField
              id="test=result"
              required
              margin="dense"
              label="test type and date (if known)"
              type="text"
              fullWidth
              variant="outlined"
              value={testResult}
              onChange={(event) => setTestResult(event.target.value)}
            />
          }
          <TextField
            id="contact-email"
            margin="dense"
            label="email"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            id="contact-message"
            required
            margin="dense"
            label="message"
            type="text"
            multiline
            rows="5"
            fullWidth
            variant="outlined"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />                                  
          <Typography variant='caption'>This is NOT a Q&A channel. We can only communicate your questions/concerns to the doctors and arrange appointments for you. 
            By submitting, you authorise Aspire Medical Centre Eastwood to contact 
            you by the contact information you provided. This channel is only monitored during office hours. For further information about how we use your data, please see our&nbsp;
          </Typography>
          <Link color='primary' variant='caption' href='/privacy-policy' underline="always" target='_blank'>Privacy Policy</Link>
        </DialogContent>
        <DialogActions className={classes.button}>
          <Button 
            id="send-btn"
            variant="contained" 
            onClick={handleSend} 
            color="primary" 
            disabled={name === '' || phone === '' || (email === '' && preferredMethod === 'email') || message === '' || 
              (reason !== "else" && (patName === '' || dob === ''))}
            fullWidth
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default memo(ContactForm)