import React, { useState, createContext } from "react"

const LoggedInContext = createContext()

const LoggedInContextProvider = ({children}) => {
  const [loggedIn, setLoggedIn] = useState(false)

  return (
    <LoggedInContext.Provider value={{loggedIn, setLoggedIn}}>
      {children}
    </LoggedInContext.Provider>
  )
}

export { LoggedInContext, LoggedInContextProvider }