import React, { useState, useContext, useEffect } from "react"
import './navbar.css'
import {
  MDBContainer, MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, 
  MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon
} from "mdbreact"
import Button from '@material-ui/core/Button'
import { Link, navigate } from 'gatsby'
import CustomNavLink from './custom-link'
import Logo from "../images/AMCE.png"
import { logout, getUser, isAdmin, isLoggedIn } from './auth/app-user'
import { aspireMCPhoneNo, aspireMCPhone } from "../utils/booking-api"
import { LoggedInContext } from './context'
import PhoneIcon from '@material-ui/icons/Phone'
import EventIcon from '@material-ui/icons/Event'
const newPatientForm = "https://register.aspiremedicalcentre.com.au/"

const Navbar = () => {
  const {loggedIn, setLoggedIn} = useContext(LoggedInContext)
  const [isOpen, setIsOpen] = useState(false)
  const greeting = () => {
    const userInfo = getUser()
    return `Hello ${userInfo.given_name[0].toUpperCase()}${userInfo.family_name[0].toUpperCase()}`
  }

  useEffect(() => {
    if (isLoggedIn())
      setLoggedIn(true)
  }, [])

  const toggleCollapse = () => {
    setIsOpen(!isOpen)
  }

  const signout = () => {
    logout()
    setLoggedIn(false)
    navigate("/")
  }

  return (
    <MDBNavbar light expand="md" id='navbar'>
      <MDBContainer>
        <Link className="waves-effect waves-light" to='/' style={{display: 'flex', alignItems: 'center'}}>
          <img height={80} src={Logo} className="mr-3" alt="Aspire Medical Centre Eastwood logo" />
        </Link>
        <MDBNavbarToggler name="navbar-toggler" onClick={toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
          <MDBNavbarNav left>
            <MDBNavItem>
              <CustomNavLink to="/our-team">Our team</CustomNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <span className="mr-2">Services</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default dropdown-menu">
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/general-practitioner")}>General Practitioner</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/physiotherapist")}>Physiotherapist</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/dietitian")}>Dietitian</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/psychologist")}>Psychologist</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/pathology")}>Pathology</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/skin-cancer-clinic")}>Skin Cancer Clinic</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/medical-abortion")}>Medical Abortion</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/iron-infusion")}>Iron Infusion</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <span className="mr-2">Our Practice</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default dropdown-menu">
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/general-information")}>General Information</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/fees")}>Fees</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/contact")}>Hours, Location & Contact</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/communication-policy")}>Communication Policy</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/testresults-policy")}>Test Results Policy</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/privacy-policy")}>Privacy Policy</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/photos")}>Photos</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate(newPatientForm)}>New Patient Form</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/find-us")}>Find Us</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <span className="mr-2">Resources</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default dropdown-menu">
                  {/* <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/covid19-information")}>Covid-19 Information</MDBDropdownItem> */}
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("https://hello19215.wixsite.com/aspirecarecorner")}>Aspire Care Corner</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("https://www.youtube.com/@AspireHealthHub")}>Aspire Health Hub</MDBDropdownItem>
                  {/* <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/booking-instructions")}>Booking Instructions</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/booking-errors")}>Booking Errors</MDBDropdownItem> */}
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/appointment-browser")}>Old Book Online</MDBDropdownItem>
                  <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/health-information")}>Health Information</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>            
          </MDBNavbarNav>
          <MDBNavbarNav left>
            <MDBNavItem>
              <Button 
                variant="contained" 
                color="secondary"
                size="small"
                aria-label="Book online"
                startIcon={<EventIcon />}
                style={{marginRight: 10, marginBottom: 10}}
                onClick={() => navigate("https://www.hotdoc.com.au/medical-centres/eastwood-NSW-2122/aspire-medical-centre-eastwood/doctors")}
              >
                Book online
              </Button>
            </MDBNavItem>
            <MDBNavItem>
              <Button 
                variant="contained" 
                color="secondary"
                size="small"
                href={`tel:${aspireMCPhoneNo}`}
                aria-label="Call Aspire Medical Centre Eastwood"
                startIcon={<PhoneIcon />}
              >
                {`Call ${aspireMCPhone}`}
              </Button>
            </MDBNavItem>            
          </MDBNavbarNav>         
          <MDBNavbarNav right>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  {loggedIn ?
                    <span className="mr-2">{greeting()}</span>
                    :
                    <MDBIcon icon="user-lock" />
                  }
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default dropdown-menu">
                  {loggedIn ? 
                    <>
                      <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/my-account")}>My account</MDBDropdownItem>
                      {isAdmin() ? 
                        <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/app/update-notice")}>Update Notice</MDBDropdownItem>
                        :
                        null
                      }
                      {isAdmin() ? 
                        <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/app/message-centre")}>Message Centre</MDBDropdownItem>
                        :
                        null
                      }
                      {isAdmin() ? 
                        <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/app/booking-browser")}>Booking Browser</MDBDropdownItem>
                        :
                        null
                      }
                      {isAdmin() ? 
                        <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/app/update-request")}>SMS Patients</MDBDropdownItem>
                        :
                        null
                      }
                      {isAdmin() ? 
                        <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/app/provider-appointments")}>Provider Appointments</MDBDropdownItem>
                        :
                        null
                      }
                      <MDBDropdownItem className="black-text nav-link" onClick={signout}>Sign out</MDBDropdownItem>
                    </> 
                    :
                    <>
                      <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/signin")}>Sign in</MDBDropdownItem>
                      <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/signup")}>Create account</MDBDropdownItem>
                    </>
                  }
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  )
}

export default Navbar
