import axios from "axios"
import moment from 'moment'

const appointmentStatusURL = `${process.env.GATSBY_BP_SERVER}/appointment-status`
const getAppointmentsURL = `${process.env.GATSBY_BP_SERVER}/get-appointments`
const addAppointmentURL = `${process.env.GATSBY_BP_SERVER}/add-appointment`
const cancelAppointmentURL = `${process.env.GATSBY_BP_SERVER}/cancel-appointment`
const confirmAppointmentURL = `${process.env.GATSBY_BP_SERVER}/confirm-appointment`
const arriveAppointmentURL = `${process.env.GATSBY_BP_SERVER}/arrive-appointment`
const getPatientURL = `${process.env.GATSBY_BP_SERVER}/get-patient`
const addMessageURL = `${process.env.GATSBY_BP_SERVER}/add-message`
const addPatientURL = `${process.env.GATSBY_BP_SERVER}/add-patient`
const getPatientAptsURL = `${process.env.GATSBY_BP_SERVER}/get-patientapts`
const isTeleEligibleURL = `${process.env.GATSBY_BP_SERVER}/is-tele-eligible`
const getNumVisitsURL = `${process.env.GATSBY_BP_SERVER}/get-numvisits`
const getPatientInfoURL = `${process.env.GATSBY_BP_SERVER}/get-patientinfo`
const updatePatientMedicareURL = `${process.env.GATSBY_BP_SERVER}/update-medicare`
const updateHealthFundURL = `${process.env.GATSBY_BP_SERVER}/update-healthfund`
const updatePatientPensionURL = `${process.env.GATSBY_BP_SERVER}/update-pension`
const updatePatientContactsURL = `${process.env.GATSBY_BP_SERVER}/update-contacts`
const updatePatientAddressURL = `${process.env.GATSBY_BP_SERVER}/update-address`
const updatePatientEmailURL = `${process.env.GATSBY_BP_SERVER}/update-email`
const updateDVAURL = `${process.env.GATSBY_BP_SERVER}/update-DVA`
const setEmergencyContactURL = `${process.env.GATSBY_BP_SERVER}/set-emergency-contact`
const setNextOfKinURL = `${process.env.GATSBY_BP_SERVER}/set-next-of-kin`
const getUserAptsURL = `${process.env.GATSBY_BP_SERVER}/user-appointments-ondate`
const receptionID = 6 //Yvonne
const reception2ID = 26 //Vady
const reception3ID = 72 //Willa
const nurseID = 7  //Tina
const managerID = 2000000553 //Linda

export const fetchApps = async (startDate, numDays, doctorList, setLoading, setAppData) => {
  try {
    const now = new Date()
    const secondsFromMidNight = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds()
    if (numDays > 0 && doctorList.length > 0) {
      const config = {
        method: 'post',
        headers: {"Content-Type": "application/json"},
        url: getAppointmentsURL,
        data: {
          secondsFromMidNight: secondsFromMidNight,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          numDays: numDays,
          userList: doctorList
        }
      }
      setLoading(true)
      const response = await axios(config)
      setAppData(response.data)
      setLoading(false)
      console.log("Fetching available appointments")
    }
  } catch(err) {
    console.error(`BP_GetFreeAppointments error ${JSON.stringify(err)}`)
  }
}

/*
 * Return:
 * 0: The slot has been taken
 * -1: A booking exists on the same day
 * -2: Booking request is blocked because the patient has failed attendances >= 3
*/
export const addAppointmentToBP = async (aptDate, aptTime, aptDuration, aptType, practitionerID, patientID) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: addAppointmentURL,
      data: {
        aptDate, 
        aptTime,
        aptDuration, 
        aptType, 
        practitionerID, 
        patientID
      }
    }
    const result = await axios(config)

    return result.data
  } catch(err) {
    console.error(`BP_AddAppointment error ${JSON.stringify(err)}`)
  }
}

export const getPatientAptsFromBP = async (patientID) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: getPatientAptsURL,
      data: {
        patientID
      }
    }
    const result = await axios(config)

    return result.data
  } catch(err) {
    console.error(`BP_AddAppointment error ${JSON.stringify(err)}`)
  }
}

export const isTeleEligible = async (patientID) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: isTeleEligibleURL,
      data: {
        patientID
      }
    }
    const result = await axios(config)

    return result.data
  } catch(err) {
    console.error(`AddAppointment query error ${JSON.stringify(err)}`)
    // Disable if anything wrong
    return false
  }
}

export const confirmBPAppointment = async (aptID) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: confirmAppointmentURL,
      data: {
        aptID
      }
    }
    const result = await axios(config)

    return result.data
  } catch(err) {
    console.error(`BP_ConfirmAppointment error ${JSON.stringify(err)}`)
  }
}  

export const cancelBPAppointment = async (aptID) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: cancelAppointmentURL,
      data: {
        aptID
      }
    }
    const result = await axios(config)

    return result.data
  } catch(err) {
    console.error(`BP_CancelAppointment error ${JSON.stringify(err)}`)
  }
}  

export const arriveAppointment = async (aptId) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: arriveAppointmentURL,
      data: {
        aptID : aptId
      }
    }
    const result = await axios(config)

    return result.data
  } catch(err) {
    console.error(`BP_ArriveAppointment error ${JSON.stringify(err)}`)
  }
}

export const addMessageToBP = async (message) => {
  //Message reception, nurse and practice manager when some one leaves a message from website MessageUs
  try {
    let config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: addMessageURL,
      data: {
        userID: receptionID,
        subject: "A new message from Contact US",
        message: message,
        patID: 0
      }
    }
    await axios(config)

    config.data.userID = reception2ID
    await axios(config)

    config.data.userID = reception3ID
    await axios(config)

    config.data.userID = nurseID
    await axios(config)

    config.data.userID = managerID
    const result = await axios(config)

    return result.data
  } catch(err) {
    console.error(`BP_AddMessage error ${JSON.stringify(err)}`)
  }
}

export const getPatientFromBP = async (surname, firstname, dob) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: getPatientURL,
      data: {
        surname: surname,
        dob: dob
      }
    }
    const result = await axios(config)
    const patList = result.data

    let id = null
    let normalisedName = firstname.toUpperCase().replace(/-| /g,'')
    if (patList.length > 0) {
      patList.forEach(element => {
        const firstnameFromBP = element.firstname.toUpperCase().replace(/-| /g,'')
        if (normalisedName === firstnameFromBP)
          id = element.id
        // else if (normalisedName.includes(firstnameFromBP) || firstnameFromBP.includes(normalisedName)) {
        //   const message = `New patient ${firstname} ${surname} ${dob} may be a duplicate of existing patient ${element.firstname} ${surname}.`
        //   addMessageToBP(receptionID, "Check duplicated patients", message, element.id)
        // }
      })
    }

    return id
  } catch(err) {
    console.error(`BP_GetPatientByPartSurnameDOB error ${JSON.stringify(err)}`)
  }
}

export const addPatientToBP = async (titleCode, firstname, surname, dob, sexCode, ethnicCode=0, address1="", city="", postcode="", 
  email="", homePhone="", workPhone="", mobilePhone="", medicareNo="", medicareLineNo="", medicareExpiry="", 
  pensionCode=0, pensionNo="", pensionExpiry=null ) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: addPatientURL,
      data: {
        titleCode,
        firstname,
        surname,
        dob,
        sexCode,
        address1, 
        city, 
        postcode, 
        email, 
        homePhone, 
        workPhone, 
        mobilePhone, 
        medicareNo, 
        medicareLineNo, 
        medicareExpiry, 
        pensionCode, 
        pensionNo, 
        pensionExpiry,
        ethnicCode
      }
    }
    const result = await axios(config)

    return result.data
  } catch(err) {
    console.error(`BP_AddPatient error ${JSON.stringify(err)}`)
  }
}

export const getNumVisitsFromBP = async (bpPatientId) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: getNumVisitsURL,
      data: {
        patientID: bpPatientId
      }
    }
    const result = await axios(config)
    const numVisits = result.data

    return numVisits
  } catch(err) {
    console.error(`BP fetching number of visits error ${JSON.stringify(err)}`)
  }
}

export const fetchPatientInfo = async (patientID, setPatientInfo) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: getPatientInfoURL,
      data: {
        patientID       
      }
    }
    const result = await axios(config)
    setPatientInfo(result.data)
  } catch(err) {
    console.error(`BP_GetPatientByInternalID error ${JSON.stringify(err)}`)
  }
}

export const saveMedicare = async (patientID, medicareNo, medicareLineNo, medicareExpiry) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: updatePatientMedicareURL,
      data: {
        patientID, 
        medicareNo, 
        medicareLineNo, 
        medicareExpiry          
      }
    }
    await axios(config)
  } catch(err) {
    console.error(`BP_UpdatePatientMedicare error ${JSON.stringify(err)}`)
  }
}

export const saveHealthFund = async (patientID, healthFundNo, healthFundName, healthFundExpiry) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: updateHealthFundURL,
      data: {
        patientID, 
        healthFundNo, 
        healthFundName, 
        healthFundExpiry
      }
    }
    await axios(config)
  } catch(err) {
    console.error(`BP_UpdateHealthFund error ${JSON.stringify(err)}`)
  }
}

export const saveContacts = (patientID, homePhone, workPhone, mobilePhone, address1, city, postcode, email) => {
  let promise1, promise2, promise3
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: updatePatientContactsURL,
      data: {
        patientID, 
        homePhone, 
        workPhone, 
        mobilePhone         
      }
    }
    promise1 = axios(config)
  } catch(err) {
    console.error(`BP_UpdatePatientContacts error ${JSON.stringify(err)}`)
  }

  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: updatePatientAddressURL,
      data: {
        patientID,
        address1, 
        city, 
        postcode
      }
    }
    promise2 = axios(config)
  } catch(err) {
    console.error(`BP_UpdatePatientAddress error ${JSON.stringify(err)}`)
  }

  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: updatePatientEmailURL,
      data: {
        patientID, 
        email
      }
    }
    promise3 = axios(config)
  } catch(err) {
    console.error(`BP_UpdatePatientEmail error ${JSON.stringify(err)}`)
  }

  const promises = [promise1, promise2, promise3]
  Promise.allSettled(promises)
    .then((results) => results.forEach((result) => console.log(result.status)))

  // For unknown reason, if including setEmergencyContact and setNextOfKin in this function with promise4 and promise5, 
  // emergency contact and next of kin won't get updated in patient record even though BP returns 1 (successful status).
}

export const setEmergencyContact = async (patientID, firstname, surname, contactPhone, relationship) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: setEmergencyContactURL,
      data: {
        patientID, 
        firstname, 
        surname, 
        contactPhone, 
        relationship
      }
    }
    await axios(config)
  } catch(err) {
    console.error(`BP_SetEmergencyContact error ${JSON.stringify(err)}`)
  }
}

export const setNextOfKin = async (patientID, firstname, surname, contactPhone, relationship) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: setNextOfKinURL,
      data: {
        patientID, 
        firstname, 
        surname, 
        contactPhone, 
        relationship
      }
    }
    await axios(config)
  } catch(err) {
    console.error(`BP_SetNextOfKin error ${JSON.stringify(err)}`)
  }
}

export const savePension = async (patientID, pensionCode, pensionNo, pensionExpiry) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: updatePatientPensionURL,
      data: {
        patientID, 
        pensionCode, 
        pensionNo, 
        pensionExpiry
      }
    }
    await axios(config)
  } catch(err) {
    console.error(`BP_UpdatePatientPension error ${JSON.stringify(err)}`)
  }
}

export const saveDVA = async (patientID, dVACode, dVANo) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: updateDVAURL,
      data: {
        patientID, 
        dVACode,
        dVANo
      }
    }
    await axios(config)
  } catch(err) {
    console.error(`BP_UpdatePatientDVA error ${JSON.stringify(err)}`)
  }
}

export const appointmentStatus = async (id) => {
  try {
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: appointmentStatusURL,
      data: {
        id
      }
    }
    const result = await axios(config)
    
    return result.data
  } catch(err) {
    console.error(`BP_GetAppointmentDetails error ${JSON.stringify(err)}`)
  }
}

export const getUserApts = async (userID, aptDate, setApts) => {
  try {
    console.log(aptDate, userID)
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: getUserAptsURL,
      data: {
        userID, 
        aptDate
      }
    }
    const result = await axios(config)

    setApts(result.data)
  } catch(err) {
    console.error(`BP_GetUserBookedAppointments error ${JSON.stringify(err)}`)
  }
}

export const aspireMCEnquiryEmail = "AspireMC <admin@aspiremedicalcentre.com.au>"
export const aspireMCBookingEmail = "AspireMC <no-reply@aspiremedicalcentre.com.au>"
export const aspireMCPhoneNo = "0291899667"
export const aspireMCPhone = "(02) 9189 9667"
export const aspireMCFax = "(02) 9000 3461"
export const aspireMCABN = "ABN 70 146 472 380"
